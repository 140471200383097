<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Quantity="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Quantity, 2) }}
        </td>
      </template>
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let list = this.Bl?.DocumentYardJson ?? [];
  return list?.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardDocumentStatus: item[`YardDocumentStatus${_lang}`] ?? '',
      TransactionDate: item?.TransactionDate? DateFormater.formatOnlyDateWithSlash(item?.TransactionDate) : 'N/A',
      TransactionDateCertification: item?.TransactionDateCertification? DateFormater.formatOnlyDateWithSlash(item?.TransactionDateCertification) : 'N/A',
      Quantity: item?.YardBlDetailJson?.[0]?.Quantity ?? 0,
      ComputedWeight: item.WeigthReception ? NumberFormater.formatNumber(item.WeigthReception, 2) : '0,00',
      ComputedVolume: item.VolumenReception ? NumberFormater.formatNumber(item.VolumenReception, 2) : '0,00',
      TransaLoginCertificate: item.TransaLoginCertificate ?? 'N/A'
    
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
    { label: this.$t('label.CertificateNro'), key: 'DocumentCode', _classes: 'text-uppercase text-center' , _style:'min-width: 190px;'},
    { label: this.$t('label.CertificateDate'), key: 'TransactionDate', _classes: 'text-uppercase text-center', _style:'min-width: 190px;'},
    { label: this.$t('label.ubication'), key: 'YardName',_classes: 'text-uppercase text-center',  _style: 'min-width: 190px;'},
    { label: this.$t('label.quantity'), key: 'QuantityReceived',  _classes: 'text-uppercase text-center', _style: 'width:12%;', },
    { label: `${this.$t('label.weight')} (KGM)`, key: 'ComputedWeight',  _classes: 'text-uppercase text-center',  _style:'min-width: 150px;'},
    { label: `${this.$t('label.volume')} (M³)`, key: 'ComputedVolume', _classes: 'text-uppercase text-center', _style:'min-width: 150px;'},
    { label: this.$t('label.status'), key: 'YardDocumentStatus', _classes: 'text-uppercase text-center',  _style: 'min-width: 190px;' },
    { label: this.$t('label.CertificationDocumentDate'), key: 'TransactionDateCertification',_style: 'min-width: 190px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.UserCertifies'), key: 'TransaLoginCertificate', _classes: 'text-uppercase text-center', _style:'min-width: 190px;' },
    
  ];
}

export default{
  name: 'CertificateEntry',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    formatNumber,
  },
  computed: {
    computedList,
    fields,
  },
}
</script>